.assessment-table-container {
  padding-bottom: 40px;
}

.general-table-container {
  padding-bottom: 80px;
}

.survey-table-container {
  display: grid;
  width: 95%;
}

.survey-header-row {
  border-bottom: 1px var(--dark-gray) solid;
}

.survey-table-row {
  display: grid;
  grid-template-columns: 55% 20% 25%;
}

.survey-table-row > div {
  border: 1px var(--dark-gray) solid;
  border-bottom: none;
  border-left: none;
}

.survey-table-row > div:first-child {
  border-left: 1px var(--dark-gray) solid;
}

.survey-table-row > div:not(:first-child) {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.survey-link:last-child > .survey-table-row {
  border-bottom: 1px var(--dark-gray) solid;
}

.survey-table-record-cell {
  display: flex;
  align-items: center;
  flex-direction: row;
}

.survey-table-cell-progress {
  width: 1rem !important;
  height: 1rem !important;
  padding: 5px;
}

.survey-link:hover > * {
  background-color: var(--gray);
}

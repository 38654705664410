.card-links-container {
  color: #474747;
  text-overflow: ellipsis;
}

.tpta-accordion-text {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
  margin-right: 10px;
}

.text-download-icon {
  display: flex;
  justify-content: flex-start;
}

.new-intake-card-header-container {
  display: flex;
  justify-content: space-between;
  padding-bottom: 10px;
}

.questionnaire-name {
  color: var(--dark-gray);
}

.recent-activities-container {
  display: flex;
}

.recent-activities {
  display: block;
  font-style: italic;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
  overflow: hidden;
}

.tpta-card {
  position: relative;
  width: 100%;
  margin: 5px 0;
}

.issue-tpta-card {
  padding-right: 15px;
}

.text-icon {
  display: flex;
  justify-content: flex-start;
}

.text-icon > svg {
  fill: var(--link-blue);
}

.text-icon > svg:hover {
  cursor: pointer;
}

.view-link {
  position: absolute;
  top: 10px;
  right: 30px;
}

.vendor-name-link {
  font-size: 0.5rem;
  color: var(--secondary-blue);
}

.card-label {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 11px;
  border-radius: 20px;
  height: 25px;
  width: 150px;
}

@media screen and (max-width: 1366px) {
  .tpta-card {
    position: relative;
    margin: 5px 0;
  }

  .swimlane-card-content {
    padding: 5px !important;
    padding-right: 18px !important;
  }

  .view-link {
    top: 5px;
  }

  .dark-button {
    font-size: 11px;
  }
}

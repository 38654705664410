.favorite-icon > svg {
  fill: none;
  stroke: #6e90a3;
}

.favorite-icon.favorited > svg {
  fill: #e6be2c;
  stroke: #e6be2c;
}

@media screen and (max-width: 1376px) {
  .favorite-icon > svg {
    width: 14px;
    height: 14px;
  }

  .favorite-icon.favorited > svg {
    width: 14px;
    height: 14px;
  }
}

.top-navigation-bar-background {
  width: 100%;
  background-color: var(--primary-blue);
  border-bottom: 9px var(--secondary-blue);
  bottom: 0;
}

.logo {
  width: 110px;
  height: 38px;
  margin-right: 10px;
  padding-left: 23px;
  padding-top: 24px;
}

.header-icon-text-container {
  margin-left: 5px;
}

.header-icon-text {
  position: relative;
  margin-left: 10px;
  font-size: 32px;
  padding: 0;
  top: -3px;
}

.user-profile-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

@media screen and (max-width: 1376px) {
  .logo {
    width: 70px;
    height: 28px;
    margin-right: 5px;
    padding-left: 10px;
    padding-top: 15px;
  }

  .header-icon-text {
    top: -4px;
    margin-left: 5px;
    font-size: 22px;
    padding: 0;
  }

  .top-navigation-bar-background {
    height: 54px;
  }
}

.consults-table-accordion {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px 0;
}

.consults-table {
  display: grid;
}

.consults-table-row {
  display: grid;
  grid-template-columns: 15% 40% 15% 15% 15%;
}

.consults-state-label-cell {
  justify-content: center;
}

.consults-table-row > *[role="cell"],
.consults-table-row > *[role="columnheader"] {
  display: flex;
  align-items: center;
  border-right: 1px var(--gray) solid;
  border-top: 1px var(--gray) solid;
  padding: 6px;
}

.consults-table-row > *[role="columnheader"] {
  border-bottom: 1px var(--gray) solid;
}

.consults-table-row > *[role="cell"]:first-child,
.consults-table-row > *[role="columnheader"]:first-child {
  border-left: 1px var(--gray) solid;
}

.consults-table-row:last-child > *[role="cell"] {
  border-bottom: 1px var(--gray) solid;
  margin-bottom: 50px;
}

.consults-table-row:nth-child(2) > * {
  border-top: none;
}

.open-consults-container,
.closed-consults-container {
  margin-bottom: 10px;
}

/* Label colors */

.state-label {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 11px;
  font-family: "Amazon Ember";
  border-radius: 20px;
  height: 25px;
  width: 200px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

@media screen and (max-width: 1376px) {
  .consults-table-row {
    grid-template-columns: 13% 32% 15% 15% 15%;
  }
}

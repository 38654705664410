.breadcrumb-background {
  background-color: var(--light-gray);
  color: var(--gray);
  width: 100%;
  position: inherit;
}

.breadcrumb-seperator {
  font-size: large;
  color: var(--dark-gray);
  padding: inherit;
}

.issue-collapse-container,
.issues-table-container {
  display: grid;
  width: 95%;
}

.issues-table-container {
  margin: 20px 0;
  border-bottom: 1px var(--dark-gray) solid;
  color: var(--dark-gray);
}

.issue-collapse-container {
  padding: 6px;
}

.issue-collapse-container-requester,
.issue-table-row-requester {
  display: grid;
  grid-template-columns: 25% 15% 15% 18% 17% 10%;
}

.issue-collapse-container-vendor,
.issue-table-row-vendor {
  display: grid;
  grid-template-columns: 43% 15% 18% 14% 10%;
}

.issue-collapse-container > *:not(:first-child) {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.issue-table-row > div {
  border: 1px var(--dark-gray) solid;
  border-bottom: none;
  border-left: none;
  font-size: "14px";
}

.issue-table-row > div:first-child {
  border-left: 1px var(--dark-gray) solid;
}

.issue-table-project-name {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 6px;
}

.issue-table-project-name:hover > * {
  color: var(--link-blue);
  cursor: pointer;
}

.issue-table-severity-container,
.issue-table-state-container,
.issue-table-timeline-container,
.issue-table-shepherd,
.issue-table-shepherd-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.issue-table-severity-container {
  justify-content: flex-start;
}

.issue-accordion-favorites-container {
  display: flex;
  align-items: flex-start;
}

/* 1024px is the Zoom level for 1366px screen resolution */
@media screen and (max-width: 1376) {
  .issue-collapse-container,
  .issue-table-row {
    display: grid;
    grid-template-columns: 23% 17% 23% 25% 12%;
  }
}

.landing-page-header {
  display: flex;
  justify-content: left;
  align-items: center;
}

.swimlane-container-container {
  position: relative;
  width: 99%;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  align-items: flex-start;
}

.swimlane-container {
  width: 32%;
  display: flex;
  margin-left: 5px;
  flex-direction: column;
  justify-content: flex-start;
}

.swimlane-divider {
  background-color: var(--gray);
  height: 8px;
  width: 100%;
  border-radius: 5px;
  margin-top: 4px;
  margin-bottom: 20px;
}

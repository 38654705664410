.take-action-list-container {
  padding-bottom: 30px;
}

.take-action-list-background {
  background-color: var(--light-gray);
  border-radius: 1.25rem;
  width: 70%;
  padding: 1% 1.25% 0.5% 1%;
  height: max-content;
}

.take-action-list-card {
  width: 95%;
  border-left: 9px var(--red);
  border-style: solid;
  border-top: 0;
  border-bottom: 0;
  border-right: 0;
  margin: 6px;
}

.take-action-list-card-colorless {
  width: 95%;
  border-left: 9px var(--white);
  border-style: solid;
  border-top: 0;
  border-bottom: 0;
  border-right: 0;
  margin: 6px;
}

.take-action-list-card-content {
  font-size: 17px;
  padding: 10px;
  margin-left: 1.5%;
  font-weight: normal;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

/* stylelint-disable */
.MuiButtonBase-root.MuiAutocomplete-clearIndicator {
  color: "blue";
  visibility: "visible";
}

.MuiFormLabel-root {
  font-size: 13px;
}

.MuiAutocomplete-endAdornment {
  right: 5px !important;
}

.MuiButtonBase-root.MuiAutocomplete-clearIndicator > svg {
  width: 16px;
}

.MuiButtonBase-root.MuiAutocomplete-popupIndicator {
  padding: 0.5px;
}

.filter-bar-container {
  display: flex;
  justify-content: space-between;
}

.MuiOutlinedInput-root {
  border-radius: 30px;
}

.MuiAutocomplete-root input {
  padding-left: 1.5px !important;
}

.search-bar-textfield .MuiOutlinedInput-root {
  border-radius: 100px;
  width: 100%;
  height: 40px;
}

.search-bar {
  width: 490px;
}

@media screen and (max-width: 1376px){
  .MuiFormLabel-root {
    font-size: 11px !important;
    top: 2px !important;
  }

  .MuiButtonBase-root.MuiAutocomplete-clearIndicator > svg {
    width: 14px;
  }

  .MuiButtonBase-root.MuiAutocomplete-popupIndicator > svg {
    width: 20px;
  }

  .MuiButtonBase-root.MuiAutocomplete-clearIndicator {
    padding: 2px;
  }
  
  .MuiButtonBase-root.MuiAutocomplete-popupIndicator {
    padding: 0.2px;
  }

  .MuiAutocomplete-endAdornment {
    right: 4px !important;
  }

  .MuiOutlinedInput-root  {
    padding-right: 39px !important;
  }
}

@media screen and (min-width: 500px){
  .filter-bar-container {
    width: 100%;
  }

  .filter-label{
    /* width: 5vw; */
    width: max-content;
  }
  
  .filter-label-complete_assessments, .filter-label-track_my_assessment {
    /* width: 8.5vw; */
    width: max-content;
  }
  
  .filters {
    display: flex;
    width: 51%;
    height: 32px;
  }
  
  .filters-track_my_assessment
  {
    width: 51%;
  }
  
  .filters-complete_assessments {
    width: 47%;
  } 

  .search-bar-textfield {
    width: 100%;  
}
}

:root {
  /* Base */
  --blue-black: #0e212d;
  --off-black: #2c3042;
  --dark-gray: #666;
  --gray: #c4c4c4;
  --light-gray: #f4f4f4;
  --white: #fff;
  --black: #000;

  /* Primary */
  --primary-blue: #132f52;
  --secondary-blue: #335669;
  --tertiary-blue: #6e90a3;

  /* Secondary */
  --requester-green: #71d599;
  --assessor-orange: #e49b57;
  --vendor-purple: #a2a9ed;
  --red: #960404;
  --faded-red: #ef9494;
  --yellow: #e6be2c;
  --link-blue: #126de0;
  --dark-orange: #bc3e00;
  --mint-green: #1f8476;

  /* Nav bar measurements */
  --sidebar-width: 264px;
  --top-nav-height: 108px;
}

/* stylelint-disable -- To prevent comment removal. */
@font-face {
  font-family: "Amazon Ember";
  src: url("./assets/Fonts/AmazonEmber_Rg.eot"); /* IE9 Compat Modes */
  src:
    url("./assets/Fonts/AmazonEmber_Rg.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */
    url("./assets/Fonts/AmazonEmber_Rg.woff2") format("woff2"), /* Super Modern Browsers */
    url("./assets/Fonts/AmazonEmber_Rg.woff") format("woff"), /* Pretty Modern Browsers */
    url("./assets/Fonts/AmazonEmber_Rg.ttf") format("truetype"), /* Safari, Android, iOS */
}
/* stylelint-enable */

* > * {
  font-family: "Amazon Ember", Arial, sans-serif;
}

html {
  height: 100vh;
  width: 100vw;
}

#root {
  height: 100%;
  width: 100%;
}

body {
  height: 100%;
  width: 100%;
  margin: 0;
}

header {
  position: fixed;
  top: 0;
  width: 100vw;
  z-index: 1000;
}

main {
  position: relative;
  display: flex;
  align-items: center;
}

.main-content-container {
  position: relative;
  height: 95%;
  width: 95%;
  margin: auto;
}

.drop-down-inner-header > img,
.consults-table-accordion > img {
  height: 20px;
  width: 20px;
}

/* 1024px is the Zoom level for 1336px screen resolution */
@media screen and (max-width: 1376px) {
  main {
    width: calc(100vw - 174px);
    margin-left: 174px;
  }

  .main-content-container {
    margin: 5px;
  }
}

@media screen and (max-width: 1355px) {
  .drop-down-inner-header > img,
  .consults-table-accordion > img {
    height: 15px;
    width: 15px;
  }
}
